import {
    IAppUrl,
    IAppVersionData,
    IAppVersionItem,
    ICanRedirect,
    IContentData,
    ICountryLanguages,
    IDeviceArchitechture,
    ILanguageToggleData,
    IMappingObjectData,
    IMappingObjectDataSubPages,
    IPageMetaDataType,
    ISubPageLocaleData, StaticLocaleReturnType
} from '../../types/common';
import Analytics from "../../analytics/Analytics";
import { HomeFooterDownloadClick } from "../../analytics/AnalyticsEvents";
import { LogEvent } from "../../types/Analytics";
import { IHomeBannerTestingData } from "../../types/home";
import { ABTestingData, Environment } from "../../types/Misc.type";
import {
    getWebsiteEnvironment,
    LIVE_WEB_URL,
    STAG_WEB_URL,
    WINZO_JOGOS_DOT_COM_BR,
    WINZO_SPORT_URL,
    WINZO_JUEGOS_DOT_COM_CO,
    WINZO_JUEGOS_DOT_MX,
    WINZO_SPIELE_DOT_DE,
    THE_TECH_TRIUMPH
} from "./EnvironmentConstants";
import {
    ALL_GAMES_URL,
    BIT_32,
    BIT_64,
    categoryPagesArray,
    gamePagesWithLocale,
    GET_APP_AF2_URL,
    GET_APP_AF_URL,
    GET_APP_FACEBOOK_URL,
    GET_APP_FB_URL,
    GET_APP_KWAI_URL,
    GET_APP_META_URL,
    GET_APP_PLAY_URL,
    GET_APP_REF_URL,
    GET_APP_SNAPCHAT_URL,
    GET_APP_URL,
    HOME_PAGE_URL,
    iosAppUrl,
    NOT_FOUND,
    NOT_IN_LIST,
    pagesWithLocale,
    PLAYSTORE_PAGE,
    subPageGamePagesWithLocale,
    THE_TECH_TRIUMPH_URL,
    VariantA,
    VariantB,
} from './GlobalConstants';
import { allLocales, COUNTRY, LANGUAGE, LANGUAGE_COUNTRY, languageTextMapping, } from './LocaleAndCountryConstants';
import { Metadata } from 'next';
import { getDomainEnvForLocale } from '../helpers/helper';

let isOnline: boolean = true;
let downlaodVariant: string = VariantA;
export let isHowToVideoAvailable: boolean = false

export const setIsHowToVideoAvailable = (isHowToVideo: boolean) => {
    isHowToVideoAvailable = isHowToVideo
}

export function setDownloadVariant(variant: string) {
    downlaodVariant = variant;
}

export function getDownloadvariant(): string {
    return downlaodVariant;
}

if (typeof window !== "undefined") {
    window.addEventListener("online", () => {
        isOnline = true;
    });
    window.addEventListener("offline", () => {
        isOnline = false;
    });
}

export function getOnlineStatus(): boolean {
    return isOnline;
}

export function getQueryStrings(url?: string): any {

    let params = {}
    let queryString: string = "";

    if (url && url !== "#") {
        queryString = new URL(url).search.substring(1);
    } else if (typeof window !== "undefined") {
        queryString = window.location.search.substring(1);
    }

    if (queryString !== "") {
        const queries: string[] = queryString.split("&");

        queries.forEach((indexQuery: string) => {
            const indexPair: string[] = indexQuery.split("=");

            const queryKey: string = decodeURIComponent(indexPair[0]);
            params[queryKey] = decodeURIComponent(indexPair.length > 1 ? indexPair[1] : "");
        });
    }

    return params;
}

export function getUrlSegments(): string[] {

    if (typeof window !== "undefined") {
        const segment = window.location.pathname
        // const segment = useRouter().pathname
        const segments = segment.split("/").filter(function (el) {
            return el !== ""
        })
        return segments
    }
    return []
}

export function getHostName(): string {
    if (typeof window !== "undefined") {
        return window.location.hostname
    } else {
        return getProdHostName()
    }
}


export const pageName = (): string => {
    return getUrlSegments()[getUrlSegments().length - 1]
}

export const pagePath = (): string => {
    const url = new URL(window.location.href);
    const path = url.pathname.replace(/\//, '');
    return path;
}

export function getProdHostName(): string {
    return "www.winzogames.com"
}

const getUrl = (page: IAppVersionItem, deviceArchitecture: string | undefined, locale: string): string => {
    const randomNumber = Math.floor(Math.random() * 100) + 1
    let pageAppUrl: string

    if (deviceArchitecture === BIT_32) {
        if (randomNumber <= page.percentage) {
            pageAppUrl = page.new.x32
        } else {
            pageAppUrl = page.old.x32
        }
        const url = new URL(pageAppUrl);
        url.searchParams.append("arch", deviceArchitecture);
        pageAppUrl = url.toString();
    } else if (deviceArchitecture === BIT_64) {
        if (randomNumber <= page.percentage) {
            pageAppUrl = page.new.x64
        } else {
            pageAppUrl = page.old.x64
        }
        const url = new URL(pageAppUrl);
        url.searchParams.append("arch", deviceArchitecture);
        pageAppUrl = url.toString();
    } else {
        if (getCountryFromLocale(locale) === COUNTRY.INDIA) {
            if (randomNumber <= page.percentage) {
                pageAppUrl = page.new.x32_x64
            } else {
                pageAppUrl = page.old.x32_x64
            }
        } else {
            if (randomNumber <= page.percentage) {
                pageAppUrl = page.new.x32_x64
            } else {
                pageAppUrl = page.old.x32_x64
            }
        }
        const url = new URL(pageAppUrl);

        if (deviceArchitecture) {
            if (getCountryFromLocale(locale) === COUNTRY.INDIA) {
                url.searchParams.append("arch", deviceArchitecture);
            } else {
                url.searchParams.append("arch", deviceArchitecture);
            }
        } else {
            url.searchParams.append("arch", "vu");
        }

        pageAppUrl = url.toString();
    }

    return pageAppUrl
}

export function getAppUrls(versions: IAppVersionData, deviceArch: string | undefined, locale: string, pageName: string): IAppUrl {
    const versionJson: IAppUrl = {
        affiliate: "",
        paid: "",
        free: "",
        organic: ""
    };

    versionJson.affiliate = getUrl(versions.affiliate, deviceArch, locale)
    versionJson.paid = getUrl(versions.paid, deviceArch, locale)
    versionJson.free = getUrl(versions.free, deviceArch, locale)
    versionJson.organic = getUrl(versions.organic, deviceArch, locale)

    if (versions[pageName]) {
        versionJson[pageName] = getUrl(versions[pageName], deviceArch, locale)
    }

    return versionJson;
}

export function getAppleAppUrl(country: string): string {
    return iosAppUrl[country] ? iosAppUrl[country] : iosAppUrl[COUNTRY.BRAZIL]
}

export function handleFooterDownloadClick(): void {
    Analytics.getInstance().logEvent(HomeFooterDownloadClick as LogEvent)
}

if (typeof window !== "undefined") {
    window.addEventListener("resize", resize);
}

function resize(): void {
    isMobile = window.innerWidth < 767;
}

/** @deprecated */
export let isMobile: boolean = true;
export let isiOSDevice: boolean = false;
export const delay = 500;

export let deviceArchitecture: string = "x32_x64";
export let deviceModelNumber: string = NOT_FOUND;

export const setDeviceInfo = (architecture: string, modelNumber: string) => {
    deviceArchitecture = architecture;
    deviceModelNumber = modelNumber;
};

export function setIsMobileDevice(): void {
    isMobile = (typeof window !== "undefined") ? window.innerWidth < 767 : true
}

export function setIsIOS(): void {
    isiOSDevice = ['iPad', 'iPhone'].includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

export function getCookie(name: string): string | undefined {
    const pairs = document.cookie.split(";");
    const cookies = {};
    for (let i = 0; i < pairs.length; i++) {
        const pair = pairs[i].split("=");
        cookies[(pair[0] + '').trim()] = decodeURIComponent(pair.slice(1).join('='));
    }
    return cookies[name];
}

export function isQueryParamExist(): boolean {
    const presentUrl = new URL(window.location.href);
    if (presentUrl.searchParams.toString())
        return true;
    return false;
}

export function consoleWrapper(data: string) {
    console.log(data);
}

export const getNewHomePageABTestingData = (testing: ABTestingData): IHomeBannerTestingData | null => {
    if (!testing || !testing.newHomePageTesting) {
        return null;
    }
    const randomNumber = Math.floor(Math.random() * 100) + 1;
    let variant: string = VariantA;
    if (randomNumber > testing.newHomePageTesting.Version_A.percentage) {
        variant = VariantB;
    }
    return { executeTesting: testing.newHomePageTesting.executeTesting, variant };
}

export const iosCtaABTestingData = (testing: ABTestingData): IHomeBannerTestingData | null => {
    if (!testing || !testing.iosCtaColorTesting) {
        return null;
    }
    const randomNumber = Math.floor(Math.random() * 100) + 1;
    let variant: string = VariantA;
    if (randomNumber > testing.iosCtaColorTesting.Version_A.percentage) {
        variant = VariantB;
    }
    return { executeTesting: testing.iosCtaColorTesting.executeTesting, variant };
}

export const getHowToInstallABData = (testing: ABTestingData): IHomeBannerTestingData | null => {
    if (!testing || !testing.howToInstallStoryTesting) {
        return null;
    }
    const randomNumber = Math.floor(Math.random() * 100) + 1;
    let variant: string = VariantA;
    if (randomNumber > testing.howToInstallStoryTesting.Version_A.percentage) {
        variant = VariantB;
    }  
    return { executeTesting: testing.howToInstallStoryTesting.executeTesting, variant };
}              

export const getDownloadRedirectABTestingData = (testing: ABTestingData): IHomeBannerTestingData | null => {
    if (!testing || !testing.deviceBrandBasedDownloadUrlTesting) {
        return null;
    }
    const randomNumber = Math.floor(Math.random() * 100) + 1;
    let variant: string = VariantA;
    let brands;
    if (randomNumber > testing.deviceBrandBasedDownloadUrlTesting.Version_A.percentage) {
        variant = VariantB;
        brands = testing.deviceBrandBasedDownloadUrlTesting.Version_B.brands;
    }
    return { executeTesting: testing.deviceBrandBasedDownloadUrlTesting.executeTesting, variant, brands };
}

export const getDeviceArchitecture = (deviceArchitectureMapping: IDeviceArchitechture, modelNumber: string): string | undefined => {
    let arch = NOT_IN_LIST
    if (modelNumber === NOT_FOUND || modelNumber === "DESKTOP") {
        arch = modelNumber
    } else {
        for (const [device, architecture] of Object.entries(deviceArchitectureMapping)) {
            if (device.toUpperCase() === modelNumber.toUpperCase()) {
                arch = architecture
                break
            }
        }
    }
    setDeviceInfo(arch, modelNumber)
    return arch;
};

export function isElementInViewportFn(elem) {
    const rect = elem?.getBoundingClientRect();
    return (rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}

export const nextImageLoader = ({ src, width }): string => {
    if (width) {
        return src + `?&w=${width}&q=75`;
    }
    return src;
}

export let currentPageURL: string = "";

export const setPageURL = (param) => {

    if (param === GET_APP_AF2_URL) {
        currentPageURL = GET_APP_AF_URL;
    } else if (param === GET_APP_META_URL) {
        currentPageURL = GET_APP_FB_URL;
    } else if (param) {
        currentPageURL = param;
    }
}

export const checkIsGetAppFb = (pageName) => {
    return pageName === GET_APP_FB_URL || pageName === GET_APP_META_URL;
}

export const checkIsGetAppAF = (pageName) => {
    return pageName === GET_APP_AF2_URL || pageName === GET_APP_AF_URL;
}

// reduceRight(callback(previousValue, currentValue), initialValue)) -> Starts reducing from right 😃
export function combineHOC(...fns: Function[]) {
    return (x: any) => fns.reduceRight((y, fn) => fn(y), x);
}

export function getActualLocale(locale: string | null | undefined): string {
    return locale ? locale : LANGUAGE_COUNTRY.EN_IN;
}

export const getCountryFromLocale = (locale: string | undefined | null): string => {
    const country = locale?.split("-")[1]
    return country ? country : COUNTRY.INDIA
}

export const getLanguageFromLocale = (locale: string | undefined | null): string => {
    const lang = locale?.split("-")[0]
    return lang ? lang : LANGUAGE.ENGLISH

}

export const getStaticPagePath = (pageLocales: string[], slugKey = "slug") => {
    const finalArray: any = []
    const allowedLocales = getWebsiteEnvironment().supportedLanguages;

    pageLocales.forEach((locale) => {
        if (allowedLocales.includes(locale)) {
            let object = {
                params: {
                    [slugKey]: locale
                }
            }
            finalArray.push(object)
        }
    })
    return finalArray;
}

export const getStaticLocale = (pages: string[], slugKey = "slug"): StaticLocaleReturnType[] => {
    const finalArray: StaticLocaleReturnType[] = []
    const allowedLocales = getWebsiteEnvironment().supportedLanguages;
    pages.forEach((page) => {
        if (allowedLocales.includes(page)) {
            let object = {
                [slugKey]: page
            }
            finalArray.push(object)
        }
    })

    return finalArray
}

export const getStaticPathWithLocalePage = (gamePagesWithLocale: Map<string, IMappingObjectData>, slugKey = "slug") => {
    const finalArray: {params: StaticLocaleReturnType}[] = []
    const allowedLocales = getWebsiteEnvironment().supportedLanguages;
    Array.from<string>(gamePagesWithLocale.keys()).forEach((item) => {
        gamePagesWithLocale.get(item)!.locales.forEach((locale) => {
            if (allowedLocales.includes(locale)) {
                let object = {
                    params: {
                        [slugKey]: item,
                        locale: locale
                    }
                }
                finalArray.push(object)
            }
        })
    })

    return finalArray;
}

export const getStaticPathWithLocale = (gamePagesWithLocale: Map<string, IMappingObjectData>, slugKey = "slug") => {
    const finalArray: StaticLocaleReturnType[] = []
    const allowedLocales = getWebsiteEnvironment().supportedLanguages;
    Array.from<string>(gamePagesWithLocale.keys()).forEach((item) => {
        gamePagesWithLocale.get(item)!.locales.forEach((locale) => {
            if (allowedLocales.includes(locale)) {
                let object = {
                    [slugKey]: item,
                    locale: locale
                }
                finalArray.push(object)
            }
        })
    })

    return finalArray;
}

export const getStaticPathWithLocaleSubGamesPage = (gamePagesWithLocale: Map<string, IMappingObjectDataSubPages>) => {
    const finalArray: {params: StaticLocaleReturnType}[] = []
    const allowedLocales = getWebsiteEnvironment().supportedLanguages;
    Array.from<string>(gamePagesWithLocale.keys()).forEach((game) => {
        gamePagesWithLocale.get(game)!.subPages!.forEach((item) => {
            item.localeData.locales.forEach((locale) => {
                if (allowedLocales.includes(locale)) {
                    let object = {
                        params: {
                            slug: game,
                            subPage: item.subPage,
                            locale: locale
                        }
                    }
                    finalArray.push(object)
                }
            })
        })
    })

    return finalArray;
}

export const getStaticPathWithLocaleSubGames = (gamePagesWithLocale: Map<string, IMappingObjectDataSubPages>) => {
    const finalArray: StaticLocaleReturnType[] = []
    const allowedLocales = getWebsiteEnvironment().supportedLanguages;
    Array.from<string>(gamePagesWithLocale.keys()).forEach((game) => {
        gamePagesWithLocale.get(game)!.subPages!.forEach((item) => {
            item.localeData.locales.forEach((locale) => {
                if (allowedLocales.includes(locale)) {
                    let object = {
                        slug: game,
                        subPage: item.subPage,
                        locale: locale
                    }
                    finalArray.push(object)
                }
            })
        })
    })

    return finalArray;
}

export const handleDownloadClickCommon = (type: string, anchorEvent: HTMLAnchorElement, sendAnalytics: (type: string, btnType?: string | null) => void): void => {
    const btnType: string | null = anchorEvent?.getAttribute("data-btn-type");
    sendAnalytics(type, btnType);
}

export let userCountryCode: string = 'in';

export function setUserCountryCode(country: string): void {
    userCountryCode = country;
}

export const smsVariablesDefaultValues = {
    link: "winzo.onelink.me/D4jJ/2165e51",
    game: "Ludo, Carrom",
    win: "Rs 10 lakhs",
    bonus: "Rs 550",
    templateType: "WEBSITE_SMS_REQUEST"
}

const isColor = (bannerBg: string): boolean => {
    return bannerBg[0] === "#";
}

/** @deprecated use clamp() in CSS */
export const dynamicCssSize = (size: number, minSize: number) => {
    return "min(max(" + (size / 1920) * 100 + "vw , " + minSize + "px)," + size + "px)" // eg : min(max(10vw, 50px), 70px)
}

export const isGetAppPage = (pageName?: string): boolean => {
    return pageName === GET_APP_URL
}

export const onScrollStop = (callback: Function) => {
    let isScrolling;
    const listener = () => {
        clearTimeout(isScrolling);
        isScrolling = setTimeout(timeoutCallback, 150);
    }

    const timeoutCallback = () => {
        callback(listener);
    }

    window.addEventListener(
        'scroll',
        listener,
        false
    );
};

export const getLangugageToggleData = (pageWithLocale: Map<string, IMappingObjectData>, pageName: string, locale: string): ILanguageToggleData => {
    let languageToggleData: ILanguageToggleData;
    const languageAvailable: ICountryLanguages[] = []
    if (pageName) {
        pageWithLocale.get(pageName.toString())!.locales.forEach((item) => {
            const language = languageTextMapping.get(getLanguageFromLocale(item))
            if (language) {
                languageAvailable.push({
                    langugage: language,
                    locale: item
                });
            } else {
                throw new Error(`Language Mapping Does Not Exist For ${getLanguageFromLocale(item)}`)
            }
        })
    }

    languageToggleData = {
        toggleData: {
            image: "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/langToggle.webp",
            availableLanguages: languageAvailable
        }
    }

    return languageToggleData;
}

export const getLanguageToggleDataSubPage = (pageWithLocale: Map<string, IMappingObjectDataSubPages>, pageName: string, subPage: string, locale: string): ILanguageToggleData => {
    const data: Map<string, IMappingObjectData> = new Map()
    if (pageName) {
        pageWithLocale.get(pageName.toString())!.subPages.filter((value) => {
            return value.subPage === subPage
        }).forEach((value) => {
            data.set(value.subPage, value.localeData)
        })
    }

    return getLangugageToggleData(data, subPage, locale)
}

export const debounce = <T>(func, timeout = 500) => {
    let timer: NodeJS.Timeout;

    return (args: T | undefined = undefined, to: number | undefined = undefined) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func(args)
        }, to ? to : timeout);
    };
}

export const throttle = (callback, delay = 1000): (event) => void => {
    let throttleTimeout: NodeJS.Timeout | null = null;
    let storedEvent = null;
    const throttledEventHandler = (event) => {
        storedEvent = event;

        const shouldHandleEvent = !throttleTimeout;

        if (shouldHandleEvent) {
            callback(storedEvent);

            storedEvent = null;

            throttleTimeout = setTimeout(() => {
                throttleTimeout = null;

                if (storedEvent) {
                    throttledEventHandler(storedEvent);
                }
            }, delay);
        }
    };

    return throttledEventHandler;
};

export function getHash(value: string): string {
    let hash = 0;
    if (value.length === 0) return hash.toString();
    for (let x = 0; x < value.length; x++) {
        let ch = value.charCodeAt(x);
        hash = ((hash << 5) - hash) + ch;
        hash = hash & hash;
    }
    return hash.toString();
}

export function getId(heading: string): string {
    let id = "h_";
    id += getHash(heading);
    return id;
}

export const showToggle = (): boolean => {
    return getWebsiteEnvironment().baseUrl === LIVE_WEB_URL 
    || getWebsiteEnvironment().baseUrl === STAG_WEB_URL 
    || getWebsiteEnvironment().baseUrl === WINZO_JOGOS_DOT_COM_BR
    || getWebsiteEnvironment().baseUrl === WINZO_JUEGOS_DOT_MX
    || getWebsiteEnvironment().baseUrl === WINZO_JUEGOS_DOT_COM_CO
    || getWebsiteEnvironment().baseUrl === WINZO_SPIELE_DOT_DE;
}

export const getIntentUrlWithFallback = (deeplink: string, fallback: string): string => {
    const deeplinkParts = deeplink.split("://");
    const scheme = deeplinkParts[0];
    const intent = deeplinkParts[1];
    const urlWithFallback = `intent://${intent}#Intent;scheme=${scheme};S.browser_fallback_url=${fallback};end`
    return urlWithFallback;
}

export const getErrorMsgFromError = (error: any): string => {
    const e = error.response && error.response.data && error.response.data.errorMsg ? error.response.data.errorMsg
        : error.message.toString();
    return e;
}
export const pageToRolloutChannelMapping = {
    PAID_APP: [
        GET_APP_URL,
        GET_APP_REF_URL,
        GET_APP_FB_URL,
        GET_APP_FACEBOOK_URL,
        GET_APP_META_URL,
        GET_APP_KWAI_URL,
        GET_APP_PLAY_URL
    ],
    AFFILIATE_APP: [
        GET_APP_AF_URL,
        GET_APP_AF2_URL,
        PLAYSTORE_PAGE
    ],
    ORGANIC_APP: [
        GET_APP_SNAPCHAT_URL
    ]
}

export const getUrlWithScheme = (domain: string): string => {
    return `https://${domain}/`;
}

//This function will return only android url
export const updateAppUrls = (deviceArch: string | undefined, locale: string, appVersions: IAppVersionData, pageName: string): string => {
    let url: string;
    let appUrls: IAppUrl;

    appUrls = getAppUrls(
        appVersions,
        deviceArch,
        locale,
        pageName
    );

    if (appUrls[pageName]) {
        url = appUrls[pageName];
    } else if (pageToRolloutChannelMapping.PAID_APP.includes(pageName)) {
        url = appUrls.paid;
    } else if (pageToRolloutChannelMapping.AFFILIATE_APP.includes(pageName)) {
        url = appUrls.affiliate;
    } else if (pageToRolloutChannelMapping.ORGANIC_APP.includes(pageName)) {
        url = appUrls.organic;
    } else {
        url = appUrls.free;
    }

    return url
};

export const stripExtraTrailingSlash = (str: string) => {
    return str.endsWith('//') ? str.slice(0, -1) : str;
};

export const canRedirect = (locale: string, pathName: string): ICanRedirect => {
    let canRedirectObject: ICanRedirect = { canRedirect: false, isInPages: false }

    gamePagesWithLocale.forEach((localesData: IMappingObjectData, page: string) => {
        if (pathName === `/${ALL_GAMES_URL}/${page}`) {
            canRedirectObject.isInPages = true
            if (localesData.locales.includes(locale)) {
                canRedirectObject.canRedirect = true
            }
            return;
        }
    })

    !canRedirectObject.canRedirect && subPageGamePagesWithLocale.forEach((subPagesWithLocales: IMappingObjectDataSubPages, page: string) => {
        const gamePagePath = `/${ALL_GAMES_URL}/${page}`
        if (pathName.startsWith(gamePagePath)) {
            subPagesWithLocales.subPages.forEach((subPageLocale: ISubPageLocaleData) => {
                if (pathName === `${gamePagePath}/${subPageLocale.subPage}`) {
                    canRedirectObject.isInPages = true
                    if (subPageLocale.localeData.locales.includes(locale)) {
                        canRedirectObject.canRedirect = true
                    }
                    return;
                }
            })
            return;
        }
    })

    // ![TERMS_CONDITIONS_URL, PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL, TERMS_OF_USE_URL].includes(pathName.replace(/^\/|\/$/g, ''))
    !canRedirectObject.canRedirect && new Map([
        ...pagesWithLocale,
        ...categoryPagesArray]).forEach((localesData: IMappingObjectData, page: string) => {
        if (pathName === stripExtraTrailingSlash(`/${page}`)) {
            canRedirectObject.isInPages = true
            if (localesData.locales.includes(locale)) {
                canRedirectObject.canRedirect = true
            }
            return;
        }
    })

    return canRedirectObject
}


export const getUrlWithLocale = (locale: string, pathName: string, domainEnv?: Environment): string => {
    let pathNameWithSlash = pathName

    if (!pathName.startsWith("/")) {
        pathNameWithSlash = `/${pathName}`
    } else if (pathName === HOME_PAGE_URL) {
        pathNameWithSlash = ""
    }
    if (domainEnv && locale === domainEnv.defaultLocale) {
        return `${pathNameWithSlash}`
    }

    return `/${locale}${pathNameWithSlash}`
}

export const getLocaleFromPathname = (pathname: string) => {
    let caseSensitiveLocale = getWebsiteEnvironment().defaultLocale;
    let currentLocale = getWebsiteEnvironment().defaultLocale
    let pathNameWithoutLocale = pathname
    let pathnameHasLocale = allLocales.some((locale) => {
        if (pathname.toLowerCase().startsWith(`/${locale}/`) || pathname.toLowerCase() === `/${locale}`) {
            currentLocale = locale
            let pathArray = pathname.split("/").filter(path => path)
            caseSensitiveLocale = pathArray[0];
            pathArray.shift()
            pathNameWithoutLocale = `/${pathArray.join("/")}`
            return true
        } else {
            return false
        }
    })
    return { pathnameHasLocale, currentLocale, pathNameWithoutLocale , caseSensitiveLocale }
}

export const getCanonicalUrl = (locale: string, pageSegment: string) => {
    pageSegment = pageSegment === HOME_PAGE_URL ? "" : pageSegment
    let canonical = getUrlWithScheme(getWebsiteEnvironment().baseUrl) + locale + "/" + pageSegment;

    let domainEnv = getDomainEnvForLocale(getCountryFromLocale(locale))
    if (getWebsiteEnvironment().baseUrl === THE_TECH_TRIUMPH || pageSegment.includes(THE_TECH_TRIUMPH_URL)) {
        domainEnv = getWebsiteEnvironment(getUrlWithScheme(THE_TECH_TRIUMPH))
        pageSegment = pageSegment.replace(THE_TECH_TRIUMPH_URL, "")
    }

    if (domainEnv.defaultLocale === locale) {
        canonical = getUrlWithScheme(domainEnv.baseUrl) + pageSegment
    } else {
        canonical = getUrlWithScheme(domainEnv.baseUrl) + locale + "/" + pageSegment
    }

    if(canonical.charAt(canonical.length - 1) === "/"){
        canonical = canonical.substring(0, canonical.length - 1);
    }

    if (getWebsiteEnvironment().baseUrl === THE_TECH_TRIUMPH || pageSegment.includes(THE_TECH_TRIUMPH_URL)) {
        pageSegment = pageSegment.replace(THE_TECH_TRIUMPH_URL, "")
        canonical = getUrlWithScheme(THE_TECH_TRIUMPH) + pageSegment
    }
    
    return canonical
}

export const hrefLangObject = (locale: string, locales: string[], pageSegment: string) => {
    return locales.reduce((json, value, key) => {
        json[getHrefLang(value)] = getHrefUrl(value, pageSegment);
        if (value === locale) {
            json["x-default"] = getHrefUrl(value, pageSegment);
        }
        return json;
    }, {});
}

const getHrefLang = (locale: string) => {
    let splitValue = locale.split('-');
    return splitValue[0] + '-' + splitValue[1]?.toUpperCase()
}

const getHrefUrl = (locale: string, pageSegment: string) => {
    let href = "";
    let updatedLocaleURL = locale + '/' + pageSegment;
    if (pageSegment === HOME_PAGE_URL) {
        updatedLocaleURL = locale;
    }
    if (getWebsiteEnvironment().defaultLocale === locale
        || locale === LANGUAGE_COUNTRY.EN_US
        || locale === LANGUAGE_COUNTRY.EN_IN
        || locale === LANGUAGE_COUNTRY.PT_BR) {
        updatedLocaleURL = pageSegment;
    }

    const basePath = getUrlWithScheme(getDomainEnvForLocale(getCountryFromLocale(locale)).baseUrl)

    let url = new URL(updatedLocaleURL, basePath);

    href = url.toString()

    return href
}

export const getMetaData = (pageData: IPageMetaDataType, locales: string[], locale: string, pageUrl: string) => {
    return {
        title: pageData.content.page_title,
        description: pageData.content.meta_description,
        keywords: pageData.content.meta_keywords,
        openGraph: {
            url: getCanonicalUrl(locale, pageUrl) || "https://" + getWebsiteEnvironment().baseUrl,
            type: "website",
            images: getWebsiteEnvironment().baseUrl === WINZO_SPORT_URL ? "https://d3g4wmezrjkwkg.cloudfront.net/winzo-sports/images/logo-sports-og.png" : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/logo-gold-og.png",
            title: pageData.content.page_title,
            description: pageData.content.meta_description,
            keywords: pageData.content.meta_keywords,
        },
        alternates: {
            canonical: getCanonicalUrl(locale, pageUrl),
            languages: hrefLangObject(locale, locales, pageUrl)
        }
    }
}

export const getMinimumMetaData = (locale: string, pageUrl: string, content?: IContentData): Metadata => {
    return {
        title: content?.page_title,
        description: content?.meta_description,
        keywords: content?.meta_keywords,
        openGraph: {
            url: getCanonicalUrl(locale, pageUrl) || "https://" + getWebsiteEnvironment().baseUrl,
            type: "website",
            images: content?.og_image ? content.og_image:
                getWebsiteEnvironment().baseUrl === WINZO_SPORT_URL
                    ? "https://d3g4wmezrjkwkg.cloudfront.net/winzo-sports/images/logo-sports-og.png"
                    : "https://d3g4wmezrjkwkg.cloudfront.net/website/images/logo-gold-og.png",
            title: content?.page_title,
            description: content?.meta_description,
        },
        alternates: {
            canonical: getCanonicalUrl(locale, pageUrl),
        }
    }
}

export const getSubPageMetaDataLocales = (subPagesData : ISubPageLocaleData[], subPage: string) => {
    let locales : string[] = [];
    subPagesData.forEach((item)=>{
        if(item.subPage === subPage){
            locales = item.localeData.locales;
        }
    })
    return locales;
}